:root { 
--gray_600:#777777; 
--gray_51:#fafafa; 
--gray_700:#555555; 
--white_A700_f9:#fffffff9; 
--light_green_200:#d3bf9e; 
--red_300:#d78354; 
--gray_200:#f0f0f0; 
--gray_50:#fffcf2; 
--white_A700_87:#ffffff87; 
--bluegray_900:#333333; 
--bluegray_400:#888888; 
--deep_orange_900:#b36101; 
--black_900_18:#00000018; 
--white_A701:#fdfdff; 
--white_A700:#ffffff; 
}